function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }
function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }
import Vue from 'vue';

/* eslint-disable-next-line quotes, comma-spacing */
var API_METHODS = ["VueIntegration", "attachErrorHandler", "createTracingMixins", "init", "vueRouterInstrumentation", "attachErrorHandler", "createTracingMixins", "VueIntegration", "FunctionToString", "Hub", "InboundFilters", "ModuleMetadata", "Scope", "addBreadcrumb", "addEventProcessor", "addGlobalEventProcessor", "addIntegration", "addTracingExtensions", "captureEvent", "captureException", "captureMessage", "close", "configureScope", "continueTrace", "createTransport", "extractTraceparentData", "flush", "getActiveSpan", "getActiveTransaction", "getClient", "getCurrentHub", "getCurrentScope", "getHubFromCarrier", "lastEventId", "makeMain", "makeMultiplexedTransport", "setContext", "setExtra", "setExtras", "setMeasurement", "setTag", "setTags", "setUser", "spanStatusfromHttpCode", "startInactiveSpan", "startSpan", "startSpanManual", "startTransaction", "trace", "withScope", "BrowserClient", "makeFetchTransport", "makeXHRTransport", "defaultStackParser", "eventFromException", "eventFromMessage", "exceptionFromError", "createUserFeedbackEnvelope", "captureUserFeedback", "forceLoad", "onLoad", "showReportDialog", "wrap", "Replay", "Feedback", "BrowserTracing", "instrumentOutgoingRequests", "makeBrowserOfflineTransport", "onProfilingStartRouteTransaction", "BrowserProfilingIntegration", "GlobalHandlers", "TryCatch", "Breadcrumbs", "LinkedErrors", "HttpContext", "Dedupe", "init", "vueRouterInstrumentation"];
var delayedCalls = [];
var SentryMock = {};
var sentryReadyResolve;
var loadInitiated = false;
var loadCompleted = false;
var delayedGlobalErrors = [];
var delayedUnhandledRejections = [];
/** @param {ErrorEvent} event */
var delayGlobalError = function delayGlobalError(event) {
  delayedGlobalErrors.push([event.message, event.filename, event.lineno, event.colno, event.error]);
};
var delayUnhandledRejection = function delayUnhandledRejection(event) {
  delayedUnhandledRejections.push('reason' in event ? event.reason : 'detail' in event && 'reason' in event.detail ? event.detail.reason : event);
};
var vueErrorHandler = Vue.config.errorHandler;
Vue.config.errorHandler = (error, vm, info) => {
  if (!loadCompleted) {
    if (vm) {
      vm.$sentry.captureException(error);
    }
    if (Vue.util) {
      Vue.util.warn("Error in ".concat(info, ": \"").concat(error.toString(), "\""), vm);
    }
    console.error(error);
  }
  if (vueErrorHandler) {
    return vueErrorHandler(error, vm, info);
  }
};
export default function SentryPlugin(ctx, inject) {
  API_METHODS.forEach(key => {
    SentryMock[key] = function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return delayedCalls.push([key, args]);
    };
  });
  window.addEventListener('error', delayGlobalError);
  window.addEventListener('unhandledrejection', delayUnhandledRejection);
  inject('sentry', SentryMock);
  ctx.$sentry = SentryMock;
  var loadSentryHook = () => attemptLoadSentry(ctx, inject);
  window.onNuxtReady(loadSentryHook);
  var sentryReadyPromise = new Promise(resolve => {
    sentryReadyResolve = resolve;
  });
  var sentryReady = () => sentryReadyPromise;
  inject('sentryReady', sentryReady);
  ctx.$sentryReady = sentryReady;
}
function attemptLoadSentry(_x, _x2) {
  return _attemptLoadSentry.apply(this, arguments);
}
function _attemptLoadSentry() {
  _attemptLoadSentry = _asyncToGenerator(function* (ctx, inject) {
    if (loadInitiated) {
      return;
    }
    loadInitiated = true;
    if (!window.$nuxt) {
      // Wait for onNuxtReady hook to trigger.
      return;
    }
    yield loadSentry(ctx, inject);
  });
  return _attemptLoadSentry.apply(this, arguments);
}
function loadSentry(_x3, _x4) {
  return _loadSentry.apply(this, arguments);
} // Custom inject function that is able to overwrite previously injected values,
// which original inject doesn't allow to do.
// This method is adapted from the inject method in nuxt/vue-app/template/index.js
function _loadSentry() {
  _loadSentry = _asyncToGenerator(function* (ctx, inject) {
    if (loadCompleted) {
      return;
    }
    var {
      getConfig,
      init,
      SentrySdk
    } = yield import( /* webpackChunkName: 'sentry' */'./sentry.client.shared');
    var config = yield getConfig(ctx);
    init(_objectSpread({
      Vue
    }, config));
    loadCompleted = true;
    window.removeEventListener('error', delayGlobalError);
    window.removeEventListener('unhandledrejection', delayUnhandledRejection);
    if (delayedGlobalErrors.length) {
      if (window.onerror) {
        console.info('Reposting global errors after Sentry has loaded');
        for (var errorArgs of delayedGlobalErrors) {
          window.onerror.apply(window, errorArgs);
        }
      }
      delayedGlobalErrors = [];
    }
    if (delayedUnhandledRejections.length) {
      if (window.onunhandledrejection) {
        console.info('Reposting unhandled promise rejection errors after Sentry has loaded');
        for (var reason of delayedUnhandledRejections) {
          window.onunhandledrejection(reason);
        }
      }
      delayedUnhandledRejections = [];
    }
    delayedCalls.forEach(_ref => {
      var [methodName, args] = _ref;
      return SentrySdk[methodName].apply(SentrySdk, args);
    });
    forceInject(ctx, inject, 'sentry', SentrySdk);
    sentryReadyResolve(SentrySdk);

    // help gc

    // Avoid crashes in case the reference to the mocked object is being used after the actual Sentry instance has loaded.
    API_METHODS.forEach(key => {
      SentryMock[key] = function () {
        for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }
        return SentrySdk[key].apply(SentrySdk, args);
      };
    });

    // Dont unset delayedCalls & SentryMock during development - this will cause HMR issues.

    delayedCalls = undefined;
    SentryMock = undefined;
    sentryReadyResolve = undefined;
  });
  return _loadSentry.apply(this, arguments);
}
function forceInject(ctx, inject, key, value) {
  inject(key, value);
  var injectKey = '$' + key;
  ctx[injectKey] = value;
  window.$nuxt.$options[injectKey] = value;
}